import styled from 'styled-components';

import TextInfo from '../TextInfo';
import { Colors } from '../../../dls/Styleguide';

export const StyledInputContainer = styled.div`
  position: relative;
  & > div:has(input) {
    padding: 0 !important;
  }
  input {
    margin-top: 22px;
    width: 100%;
    font-size: 22px;
    line-height: 32px;
    height: auto;
    &::placeholder {
      color: ${Colors.Beige};
      opacity: 1; /* Firefox */
    }
  }
`;

export const StyledInputLabel = styled(TextInfo)`
  position: absolute;
  top: 0;
`;

export const StyledTextInfoWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  p {
    text-align: right;
  }
`;
