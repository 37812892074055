import { createContext, Dispatch, FC, useContext, useReducer } from 'react';
import router, { NextRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import { ApiError as KvikaApiError } from '@kvika/api-client';
import { ApiError as AudurApiError } from '@kvika/audur-api-client-v2';
import { DisruptionTypeEnum } from '@kvika/api-types';

import AudurWebApiClient from '../audurAPI/AudurWebApiClient';
import { appReducer, ActionType, AppActions } from './Reducers';
import { AnalyticsEvent, AppState } from '../types/Types';
import { useFetchData } from '../hooks/useFetchData';
import { getDefaultPrismicData, getDefaultRegistrationInfo, isUnauthorized, shouldLogToSentry } from '../utils/Utils';
import { getSentryEvent, SentryErrors } from '../utils/SentryWrapper';
import { trackEvent } from '../utils/AudurAnalytics';
import { Links } from '../dls/DataConstants';

export const initialState: AppState = {
  apiClient: new AudurWebApiClient({}),
  accounts: [],
  closedAccounts: [],
  interestSummary: undefined,
  products: [],
  audurError: {
    displayErrorToUser: false,
    apiError: undefined,
  },
  user: undefined,
  userProfile: undefined,
  phoneNumber: undefined,
  registrationInfo: getDefaultRegistrationInfo(),
  prismicData: getDefaultPrismicData(),
  usersChildren: undefined,
  serviceStatusMode: {
    message: '',
  },
  showChildrensAccounts: false,
  showChildBecameAdult: false,
  loading: {
    isLoadingAccounts: true,
    isLoadingInterestSummary: true,
    isLoadingProducts: true,
  },
  isCompanyEntity: false,
  companyInfo: undefined,
  access: undefined,
};

const AppContext = createContext<{
  state: AppState;
  dispatch: Dispatch<AppActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const AppProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  useFetchData({ user: state.user, dispatch, errorHandling });
  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export function useApiClient() {
  return useContext(AppContext).state.apiClient;
}

export function useAppContext() {
  return useContext(AppContext);
}

export type ErrorHandlingProps = {
  dispatch: Dispatch<AppActions>;
  error?: AudurApiError | KvikaApiError;
  displayErrorToUser: boolean;
  sentryErrorString?: SentryErrors;
  analyticsData?: AnalyticsEvent;
};

export function errorHandling({
  dispatch,
  error,
  displayErrorToUser,
  sentryErrorString,
  analyticsData,
}: ErrorHandlingProps) {
  const status = error?.response?.status;

  const disruptionType = error?.response?.headers['x-disruption-type'] as DisruptionTypeEnum | undefined;
  const isMaintenanceMode = status === 503 && disruptionType === DisruptionTypeEnum.MAINTENANCE;

  // No need to do analytics, log to Sentry or display error to user if maintenance mode is on
  if (isMaintenanceMode) {
    return;
  }

  if (analyticsData) {
    trackEvent(analyticsData);
  }

  if (shouldLogToSentry(error) && sentryErrorString) {
    Sentry.captureEvent(getSentryEvent(error, sentryErrorString));
  }

  if (isUnauthorized(status)) {
    dispatch({ type: ActionType.LogoutUser });
    if (router.pathname !== Links.Login) {
      router.push(Links.Login);
    }
  }
  dispatch({
    type: ActionType.UpdateApiError,
    payload: { apiError: error, displayErrorToUser },
  });
}

export const logOut = (dispatch: Dispatch<AppActions>, router: NextRouter) => {
  dispatch({ type: ActionType.LogoutUser });
  router.push(Links.Login);
};

export { AppContext, AppProvider };
