import { Dispatch, useEffect } from 'react';
import { DateTime } from 'luxon';
import { LoginResponseSchema } from '@kvika/audur-api-types';

import AudurWebApiClient from '../audurAPI/AudurWebApiClient';
import { ActionType, AppActions } from '../store/Reducers';
import { ErrorHandlingProps } from '../store/AppContext';
import AudurPrismicClient from '../prismic/PrismicClient';
import { AudurMarketInterestRates, AudurMarketInterestRatesConnectionConnection, Maybe } from '../prismic/PrismicTypes';
import { getNodesFromConnection } from '../prismic/PrismicUtils';

type FetchDataConfig = {
  user: LoginResponseSchema | undefined;
  dispatch: Dispatch<AppActions>;
  errorHandling: (errorHandlingProps: ErrorHandlingProps) => void;
};

export const useFetchData = ({ user, dispatch, errorHandling }: FetchDataConfig) => {
  const hasUser = Boolean(user);
  const pepApprovalPending = user?.pepApprovalPending;
  const isNewUser = user && 'isNewUser' in user && user.isNewUser;

  // fetch isCompanyAccess from api

  useEffect(() => {
    const expiration = DateTime.now().plus({ minutes: 30 });
    const apiClient = new AudurWebApiClient({ expiration, dispatch });
    dispatch({
      type: ActionType.UpdateApiClient,
      payload: apiClient,
    });

    if (!hasUser || pepApprovalPending || isNewUser) {
      return;
    }

    if (process.env.NEXT_PUBLIC_PRISMIC_API_TOKEN) {
      const prismicClient = new AudurPrismicClient(process.env.NEXT_PUBLIC_PRISMIC_API_TOKEN);
      prismicClient
        .getMarketInterestRates({ variables: { lang: 'is' } })
        .then((interestRatesConnection: Maybe<AudurMarketInterestRatesConnectionConnection>) => {
          const marketInterestRatesList: AudurMarketInterestRates[] = getNodesFromConnection(interestRatesConnection);
          const marketInterestRates = marketInterestRatesList.find(
            (item) =>
              !!item.savingsMarketInterestRates &&
              !!item.termDepositMarketInterestRates &&
              !!item.futureMarketInterestRates
          );
          if (!marketInterestRates) return;

          const savingsMarketInterestRates = marketInterestRates.savingsMarketInterestRates?.find(
            (item) => 'highDepositRate' in item
          );
          const termDepositMarketInterestRates = marketInterestRates.termDepositMarketInterestRates?.find(
            (item) => 'sixMonthTermRate' in item
          );
          const futureMarketInterestRates = marketInterestRates.futureMarketInterestRates?.find(
            (item) => 'averageMarketFutureRate' in item
          );

          const { inflationRateGoal } = marketInterestRates;
          if (
            savingsMarketInterestRates &&
            termDepositMarketInterestRates &&
            futureMarketInterestRates &&
            inflationRateGoal
          ) {
            dispatch({
              type: ActionType.UpdatePrismicData,
              payload: {
                savingsMarketInterestRates,
                termDepositMarketInterestRates,
                futureMarketInterestRates,
                inflationRateGoal,
                marketInterestRatesCompany: {
                  highDepositRate: 7.64,
                  lowDepositRate: 7.43,
                  midDepositRate: 7.48,
                  midToHighDepositRate: 7.54,
                },
              },
            });
          }
        })
        .catch(() => {
          // TODO: Log to Sentry
        });
    }
  }, [dispatch, errorHandling, hasUser, isNewUser, pepApprovalPending, user?.isChild]);
};
