import { Dispatch, FC, useReducer, useContext, createContext } from 'react';
import { SurveyState } from '@kvika/audur-utils';

import { SurveyActions, surveyReducer } from './SurveyReducer';

const initialState: SurveyState = {
  survey: { id: 0, questionGroups: [] },
  answers: [],
};

const SurveyContext = createContext<{
  state: SurveyState;
  dispatch: Dispatch<SurveyActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const SurveyProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(surveyReducer, initialState);

  return <SurveyContext.Provider value={{ state, dispatch }}>{children}</SurveyContext.Provider>;
};

export function useSurveyContext() {
  return useContext(SurveyContext);
}

export { SurveyContext, SurveyProvider };
