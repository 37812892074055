import { CSSProperties } from 'react';

import { Colors } from '../../dls/Styleguide';
import { StyledTextInfo } from '../styledComponents/TextInfoStyles';

type Props = {
  text?: string | null;
  fontSize?: string;
  fontSizeMobile?: string;
  bold?: boolean;
  color?: string;
  lineHeight?: string;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
};

const TextInfo = ({ text, fontSize, fontSizeMobile, bold, color, lineHeight, style, className, onClick }: Props) => {
  return (
    <StyledTextInfo
      style={{ color: color || Colors.Beige, fontWeight: bold ? 'bold' : 'normal', ...style }}
      fontSize={fontSize}
      fontSizeMobile={fontSizeMobile}
      lineHeight={lineHeight}
      className={className}
      onClick={onClick}
    >
      {text}
    </StyledTextInfo>
  );
};

export default TextInfo;
