import { ApiError as KvikaApiError } from '@kvika/api-client';
import { ApiError as AudurApiError } from '@kvika/audur-api-client-v2';

import AudurModal from './AudurModal';
import { getErrorHeading, getErrorMessage, getErrorMessageAsString } from '../../utils/Utils';
import { FontSizePx } from '../../dls/Styleguide';
import { ServerErrorMessage } from '../../types/APITypes';

type Props = {
  isModalOpen: boolean;
  handleClose: () => void;
  serverError?: AudurApiError | KvikaApiError;
};

const ServerErrorModal = ({ isModalOpen, handleClose, serverError }: Props) => {
  const responseDetail = getErrorMessageAsString(serverError);
  // We use the detail property to map to Icelandic error messages.
  // However, if detail is "Internal server error" we want to use the message property instead since that is more specific.

  const errorData = {
    message: responseDetail === ServerErrorMessage.INTERNAL_SERVER_ERROR ? serverError?.message : responseDetail,
    type: serverError?.response?.data.type,
    code: serverError?.response?.data.code ?? '',
  };

  const errorMessage = getErrorMessage(errorData);
  const errorHeading = getErrorHeading(errorMessage);

  return (
    <AudurModal isOpen={isModalOpen} handleClose={handleClose} title={errorHeading}>
      <div style={{ fontSize: FontSizePx.BodyLarge }}>{errorMessage}</div>
    </AudurModal>
  );
};
export default ServerErrorModal;
