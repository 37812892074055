import ContentLoader from 'react-content-loader';
import {
  StyledAccountsLoader,
  StyledAccountsMobileLoader,
  StyledInterestSummaryLoader,
  StyledInterestSummaryMobileLoader,
  StyledhighlightCardLoader,
  StyledhighlightCardMobileLoader,
  StyledAccountsPageloader,
  StyledMobileAccountsPageLoader,
  StyledTransactionsLoader,
  StyledTransactionsMobileLoader,
} from '../components/styledComponents/ContentLoadersStyles';
import { Colors } from '../dls/Styleguide';

const getLoaderProps = (uniqueKey: string) => {
  return {
    uniqueKey,
    color: Colors.Beige,
    foregroundColor: Colors.LightCardGray,
    backgroundColor: Colors.CardGray,
    backgroundOpacity: 0.5,
    style: { width: '100%' },
  };
};

export const accountsLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <StyledAccountsLoader>
      <ContentLoader viewBox="0 0 300 70" {...props}>
        <rect x="0" y="10" rx="2" ry="2" width="200" height="12" />
        <rect x="0" y="25" rx="2" ry="2" width="120" height="10" />
        <rect x="0" y="44" rx="2" ry="2" width="50" height="8" />
      </ContentLoader>
    </StyledAccountsLoader>
  );
};

export const accountsMobileLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <StyledAccountsMobileLoader>
      <ContentLoader viewBox="0 0 50 64" {...props}>
        <rect x="0" y="10" rx="2" ry="2" width="100%" height="12" />
        <rect x="0" y="25" rx="2" ry="2" width="100%" height="10" />
        <rect x="0" y="44" rx="2" ry="2" width="100%" height="8" />
        <rect x="0" y="56" rx="2" ry="2" width="100%" height="8" />
      </ContentLoader>
    </StyledAccountsMobileLoader>
  );
};

export const calculatorLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <ContentLoader viewBox="0 0 150 200" {...props}>
      <rect x="0" y="8" rx="2" ry="2" width="60" height="15" />
      <rect x="70" y="8" rx="2" ry="2" width="60" height="15" />
      <rect x="0" y="40" rx="2" ry="2" width="130" height="10" />
      <rect x="0" y="60" rx="2" ry="2" width="130" height="10" />
      <rect x="0" y="80" rx="2" ry="2" width="130" height="10" />
      <rect x="0" y="100" rx="2" ry="2" width="30" height="10" />
      <rect x="0" y="120" rx="2" ry="2" width="40" height="10" />
    </ContentLoader>
  );
};

export const interestSummaryLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <StyledInterestSummaryLoader>
      <ContentLoader viewBox="0 0 150 50" {...props}>
        <rect x="0" y="0" rx="2" ry="2" width="120" height="6" />
        <rect x="0" y="10" rx="2" ry="2" width="100" height="8" />
        <rect x="0" y="30" rx="2" ry="2" width="150" height="20" />
      </ContentLoader>
    </StyledInterestSummaryLoader>
  );
};

export const interestSummaryMobileLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <StyledInterestSummaryMobileLoader>
      <ContentLoader viewBox="0 0 120 50" {...props}>
        <rect x="0" y="0" rx="2" ry="2" width="100" height="6" />
        <rect x="0" y="10" rx="2" ry="2" width="100" height="8" />
        <rect x="0" y="30" rx="2" ry="2" width="120" height="20" />
      </ContentLoader>
    </StyledInterestSummaryMobileLoader>
  );
};

export const highlightCardLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <StyledhighlightCardLoader>
      <ContentLoader viewBox="0 0 140 50" {...props}>
        <rect x="0" y="8" rx="2" ry="2" width="100" height="10" />
        <rect x="0" y="24" rx="2" ry="2" width="60" height="12" />
      </ContentLoader>
    </StyledhighlightCardLoader>
  );
};

export const highlightCardMobileLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <StyledhighlightCardMobileLoader>
      <ContentLoader viewBox="0 0 120 20" {...props}>
        <rect x="0" y="8" rx="2" ry="2" width="100" height="10" />
      </ContentLoader>
    </StyledhighlightCardMobileLoader>
  );
};

export const accountsPageLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <StyledAccountsPageloader>
      <ContentLoader viewBox="0 0 200 100" {...props}>
        <rect x="4" y="8" rx="1" ry="1" width="60" height="6" />
        <rect x="4" y="16" rx="1" ry="1" width="120" height="30" />
        <rect x="4" y="50" rx="1" ry="1" width="36" height="20" />
        <rect x="44" y="50" rx="1" ry="1" width="80" height="20" />
        <rect x="128" y="8" rx="1" ry="1" width="50" height="62" />
      </ContentLoader>
    </StyledAccountsPageloader>
  );
};

export const mobileAccountsPageLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <StyledMobileAccountsPageLoader>
      <ContentLoader viewBox="0 0 200 200" {...props}>
        <rect x="8" y="8" rx="2" ry="2" width="100" height="15" />
        <rect x="8" y="30" rx="2" ry="2" width="180" height="60" />
        <rect x="8" y="100" rx="2" ry="2" width="180" height="40" />
        <rect x="8" y="150" rx="2" ry="2" width="180" height="40" />
      </ContentLoader>
    </StyledMobileAccountsPageLoader>
  );
};

export const transactionsLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <StyledTransactionsLoader>
      <ContentLoader viewBox="0 0 300 100" {...props}>
        <rect x="0" y="12" rx="1" ry="1" width="300" height="14" />
        <rect x="0" y="28" rx="1" ry="1" width="300" height="14" />
        <rect x="0" y="44" rx="1" ry="1" width="300" height="14" />
        <rect x="0" y="60" rx="1" ry="1" width="300" height="14" />
      </ContentLoader>
    </StyledTransactionsLoader>
  );
};

export const transactionsMobileLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <StyledTransactionsMobileLoader>
      <ContentLoader viewBox="0 0 150 100" {...props}>
        <rect x="0" y="12" rx="1" ry="1" width="150" height="14" />
        <rect x="0" y="28" rx="1" ry="1" width="150" height="14" />
        <rect x="0" y="44" rx="1" ry="1" width="150" height="14" />
        <rect x="0" y="60" rx="1" ry="1" width="150" height="14" />
      </ContentLoader>
    </StyledTransactionsMobileLoader>
  );
};

export const sideBarLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <ContentLoader viewBox="0 0 100 250" {...props}>
      <rect x="0" y="0" rx="1" ry="1" width="200" height="50" />
      <rect x="0" y="60" rx="1" ry="1" width="300" height="10" />
      <rect x="0" y="80" rx="1" ry="1" width="300" height="10" />
      <rect x="0" y="100" rx="1" ry="1" width="300" height="10" />
      <rect x="0" y="120" rx="1" ry="1" width="300" height="10" />
      <rect x="0" y="140" rx="1" ry="1" width="300" height="10" />
      <rect x="0" y="160" rx="1" ry="1" width="300" height="10" />
      <rect x="0" y="180 " rx="1" ry="1" width="300" height="10" />
      <rect x="0" y="200" rx="1" ry="1" width="300" height="10" />
      <rect x="0" y="220" rx="1" ry="1" width="300" height="10" />
    </ContentLoader>
  );
};

export const progressBarLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <ContentLoader viewBox="0 0 165 10" {...props}>
      <rect x="0" y="0" rx="1" ry="1" width="15" height="2" />
      <rect x="20" y="0" rx="1" ry="1" width="15" height="2" />
      <rect x="40" y="0" rx="1" ry="1" width="15" height="2" />
      <rect x="60" y="0" rx="1" ry="1" width="15" height="2" />
      <rect x="80" y="0" rx="1" ry="1" width="15" height="2" />
      <rect x="100" y="0" rx="1" ry="1" width="15" height="2" />
      <rect x="120" y="0" rx="1" ry="1" width="15" height="2" />
      <rect x="140" y="0" rx="1" ry="1" width="15" height="2" />
    </ContentLoader>
  );
};

export const recurringAMLLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <ContentLoader viewBox="0 0 165 250" {...props}>
      <rect x="0" y="15" rx="1" ry="1" width="400" height="25" />
      <rect x="0" y="50" rx="1" ry="1" width="400" height="25" />
      <rect x="0" y="85" rx="1" ry="1" width="400" height="25" />
      <rect x="0" y="120" rx="1" ry="1" width="400" height="25" />
      <rect x="0" y="155" rx="1" ry="1" width="400" height="25" />
      <rect x="0" y="190" rx="1" ry="1" width="400" height="25" />
      <rect x="0" y="225" rx="1" ry="1" width="400" height="25" />
      <rect x="0" y="260" rx="1" ry="1" width="400" height="25" />
    </ContentLoader>
  );
};

export const transferLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <ContentLoader viewBox="0 0 100 125" {...props}>
      <rect x="0" y="0" rx="2" ry="2" width="100" height="125" />
    </ContentLoader>
  );
};

export const settingsLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <ContentLoader viewBox="0 0 165 95" {...props}>
      <rect x="0" y="0" rx="1" ry="1" width="400" height="20" />
      <rect x="0" y="25" rx="1" ry="1" width="400" height="20" />
      <rect x="0" y="50" rx="1" ry="1" width="400" height="20" />
      <rect x="0" y="75" rx="1" ry="1" width="400" height="20" />
    </ContentLoader>
  );
};

export const highlightDashoardCardLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <StyledhighlightCardLoader>
      <ContentLoader viewBox="0 0 100 120" {...props}>
        <rect x="0" y="0" rx="2" ry="2" width="100" height="120" />
      </ContentLoader>
    </StyledhighlightCardLoader>
  );
};

export const highlightDashoardCardMobileLoader = (uniqueKey: string) => {
  const props = getLoaderProps(uniqueKey);
  return (
    <StyledhighlightCardMobileLoader>
      <ContentLoader viewBox="0 0 100 50" {...props}>
        <rect x="0" y="0" rx="2" ry="2" width="100" height="50" />
      </ContentLoader>
    </StyledhighlightCardMobileLoader>
  );
};
