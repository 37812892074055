import { TooltipPlacement } from '../../../types/UITypes';
import { StyledTooltip } from './TooltipStyles';
import FontWrapper from '../../../styles/FontWrapper';

type Props = {
  id: string;
  title: string;
  placement?: TooltipPlacement;
  children: JSX.Element;
  open?: boolean;
  showOnHover?: boolean;
};

const AudurBasicTooltip = ({ id, title, placement, children, open = false, showOnHover = true }: Props) => {
  return (
    <StyledTooltip
      id={id}
      content={<FontWrapper>{title}</FontWrapper>}
      placement={placement}
      toggledOpen={open}
      showOnHover={showOnHover}
      fontFamily="var(--CircularXX), Arial, sans-serif"
    >
      {children}
    </StyledTooltip>
  );
};

export default AudurBasicTooltip;
