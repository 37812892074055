import { forwardRef, Ref, useState, useEffect } from 'react';
import { toIskCurrencyString } from '@kvika/string-utils';
import { stringToValidInteger } from '@kvika/audur-utils';

import { getTransferInputMaxLength } from '../../../utils/Utils';
import AudurTextInput from '../AudurTextInput';
import { Colors, FontSizeRem } from '../../../dls/Styleguide';
import { MAX_AMOUNT } from '../../../utils/Constants';
import { StyledInputContainer, StyledInputLabel, StyledTextInfoWrapper } from './TransferInputStyles';
import TextInfo from '../TextInfo';
import { TranslatedStrings } from '../../../utils/Translations';

type Props = {
  balance?: number;
  amount: string;
  setAmount(amount: string): void;
  autoFocus?: boolean;
  shouldIgnoreTab?: boolean;
  isIndexedSourceAccount?: boolean;
};

export const getDiffBalance = (transferAmount: number, accountBalance: number) => {
  const diffAmount = accountBalance - transferAmount;
  return diffAmount;
};

const TransferInput = forwardRef(
  (
    { balance, amount, setAmount, autoFocus, shouldIgnoreTab, isIndexedSourceAccount }: Props,
    ref: Ref<HTMLInputElement>
  ) => {
    const [diffBalance, setDiffBalance] = useState<number | undefined>(balance);
    const [shouldShowBalance, setShouldShowBalance] = useState(true);

    useEffect(() => {
      const transferAmount = stringToValidInteger(amount);
      if (balance !== undefined && Number.isInteger(transferAmount)) {
        const diffBalance = balance - transferAmount;
        setDiffBalance(diffBalance);
      }
    }, [amount, balance]);

    useEffect(() => {
      if (balance) {
        const transferAmount = stringToValidInteger(amount);
        const diffBalance = Number.isInteger(transferAmount) ? getDiffBalance(transferAmount, balance) : balance;
        setDiffBalance(diffBalance);
        if (stringToValidInteger(amount) > 0) {
          // TODO: Remove the double bang operator (!!) and regression test
          setShouldShowBalance(!!(parseFloat(amount) < MAX_AMOUNT));
        }
      }
    }, [amount, balance]);

    return (
      <StyledInputContainer>
        <StyledInputLabel text={TranslatedStrings.is.transfer.amount} />
        {diffBalance !== undefined && shouldShowBalance && (
          <StyledTextInfoWrapper>
            <TextInfo
              text={
                isIndexedSourceAccount
                  ? TranslatedStrings.is.transfer.availableAmountToUse
                  : TranslatedStrings.is.transfer.accountStatus
              }
              color={Colors.LightGray}
            />
            <TextInfo
              text={toIskCurrencyString(diffBalance, true)}
              fontSize={FontSizeRem.H2}
              color={Colors.LightGray}
              lineHeight="32px"
            />
          </StyledTextInfoWrapper>
        )}
        <AudurTextInput
          placeholder="0 kr."
          onChange={(value: string) => setAmount(value)}
          value={amount}
          isCurrency
          autoFocus={autoFocus}
          shouldIgnoreTab={shouldIgnoreTab}
          ref={ref}
          name="transferInput"
          width="100%"
          maxLength={getTransferInputMaxLength(amount)}
        />
      </StyledInputContainer>
    );
  }
);
TransferInput.displayName = 'TransferInput';

export default TransferInput;
