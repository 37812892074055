import { useState } from 'react';
import { CircleInfo, CloseSmall } from '@kvika/audur-icons';

import { Colors } from '../../../dls/Styleguide';
import { TooltipPlacement } from '../../../types/UITypes';
import {
  StyledButton,
  StyledClickableButton,
  StyledContainer,
  StyledFlexContainer,
  StyledHeading,
  StyledText,
  StyledTooltip,
} from './TooltipStyles';
import FontWrapper from '../../../styles/FontWrapper';

type TooltipProps = {
  id: string;
  text: string;
  heading?: string;
  placement?: TooltipPlacement;
};

type TooltipContentProps = {
  text: string;
  heading?: string;
  onClose(): void;
};

const TooltipContent = ({ text, heading, onClose }: TooltipContentProps) => {
  return (
    <FontWrapper>
      <StyledContainer>
        <StyledFlexContainer>
          <StyledHeading>{heading}</StyledHeading>
          <StyledClickableButton onClick={onClose}>
            <CloseSmall />
          </StyledClickableButton>
        </StyledFlexContainer>
        <StyledText>{text}</StyledText>
      </StyledContainer>
    </FontWrapper>
  );
};

const AudurTooltip = ({ id, text, heading, placement }: TooltipProps) => {
  const [isClickOpen, setIsClickOpen] = useState(false);

  const handleTooltipClickClose = () => {
    setIsClickOpen(false);
  };

  const handleTooltipClickOpen = () => {
    setIsClickOpen(true);
  };

  return (
    <StyledTooltip
      id={id}
      content={<TooltipContent text={text} heading={heading} onClose={handleTooltipClickClose} />}
      toggledOpen={isClickOpen}
      placement={placement}
      fontFamily="var(--CircularXX), Arial, sans-serif"
    >
      <StyledButton onClick={handleTooltipClickOpen}>
        <CircleInfo color={Colors.LightOrange} />
      </StyledButton>
    </StyledTooltip>
  );
};

export default AudurTooltip;
