import localFont from 'next/font/local';
import styled, { createGlobalStyle } from 'styled-components';

export const MediaQueries = {
  mobile: '@media all and (max-width: 45em)',
  tablet: '@media all and (min-width: 45em) and (max-width: 64em)',
  desktop: '@media all and (min-width: 64em)',
  desktopSm: '@media screen and (max-width: 1440px)',
};

export const MobileOnly = styled.div`
  ${MediaQueries.mobile} {
    display: block;
  }

  display: none;
`;

export const DesktopOnly = styled.div`
  ${MediaQueries.desktop} {
    display: block;
  }

  ${MediaQueries.tablet} {
    display: block;
  }

  display: none;
`;

export const GlobalStyles = createGlobalStyle`
    @font-face {
      font-family: CircularXX;
      src: url('fonts/CircularXXWeb-Regular.woff') format('woff');
      font-weight: normal;
      font-style: normal;
    }

    @font-face {
      font-family: CircularXX;
      src: url('fonts/CircularXXWeb-Bold.woff') format('woff');
      font-weight: bold;
      font-style: normal;
    }

    @font-face {
      font-family: CircularXX;
      src: url('fonts/CircularXXWeb-Light.woff') format('woff');
      font-weight: 300;
      font-style: normal;
    }

    @font-face {
      font-family: CircularXX;
      src: url('fonts/CircularXXWeb-Book.woff') format('woff');
      font-weight: 450;
      font-style: normal;
    }

    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        font-family: var(--CircularXX), Arial, sans-serif;
    }

    html {
        padding: 0;
        margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: bold;
    }
    
    body {
        margin: 0;
        padding: 0;
        background-color: #252B39;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow-x: hidden;
    }

    
    /* Hide bullet point in lists */
    li {
      list-style-type: none;
    }

    /* Hide up/down arrows in number inputs */
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    a {
      text-decoration: unset;
    }

    @media print {
      body {
        /* Fix issue in iPads where printable area still retained dark background */
        background-color: white;
      }
      body * {
        visibility: hidden;
      }
    }
`;

export const circularXX = localFont({
  variable: '--CircularXX',
  src: [
    {
      path: '../../public/fonts/CircularXXWeb-Light.woff',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../../public/fonts/CircularXXWeb-Regular.woff',
      weight: 'normal',
      style: 'normal',
    },
    {
      path: '../../public/fonts/CircularXXWeb-Book.woff',
      weight: '450',
      style: 'normal',
    },
    {
      path: '../../public/fonts/CircularXXWeb-Bold.woff',
      weight: 'bold',
      style: 'normal',
    },
  ],
});
